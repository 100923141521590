import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Schlangenleder-Optik"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Wie euch wahrscheinlich überall in den Stores aufgefallen ist, ist Schlangenleder-Print
            diesen Herbst und Winter wieder im Trend. Egal ob echtes Schlangenleder,
            Schlangenleder-Print oder nur Schlangenleder-Optik. Die Hollywood-Schauspielerin Eva
            Longoria trägt ein Oberteil von Victoria Beckham und einen plissierten Midi-Rock mit
            Schlangenleder-Print. Dazu eine passende pinke{" "}
            <Link href="/abendtaschen/" underline="always">
              Clutch
            </Link>{" "}
            und pinke High-Heels. Dieser Look ist ein Traum.
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B3DU-gln8ne/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25352644695&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/21a8e814-c619-5752-86e3-8820b04a4a6b?w=1528&h=2200",
                    title: "",
                  },
                ],
                name: "Frogbox Chiffonbluse",
                price: 129.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608994657&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33600340?w=1786&h=2500",
                    title: "",
                  },
                ],
                name: "Rabe Schlupfbluse",
                price: 49.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24647317675&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/b68b8ba9-c871-544f-b149-7589b2eab360?w=1878&h=2500",
                    title: "",
                  },
                ],
                name: "Amy Vermont Longbluse",
                price: 59.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24890394005&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33667054?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Only Druckkleid",
                price: 39.99,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=25431230455&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35888926?w=2187&h=2088",
                    title: "",
                  },
                ],
                name: "LASCANA Stiefelette",
                price: 79.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25532598069&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/d669eb70-3613-5706-bb7a-8ec0045b2110?w=1654&h=2200",
                    title: "",
                  },
                ],
                name: "TOM TAILOR Denim Rüschenbluse",
                price: 39.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25124675243&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35754300?w=1666&h=2500",
                    title: "",
                  },
                ],
                name: "Studio Untold Klassische Bluse",
                price: 39.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24640828723&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34318710?w=1877&h=2500",
                    title: "",
                  },
                ],
                name: "Liva Loop Chelsea Boot",
                price: 39.99,
              },
            ]}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B3AEQNyA2Y2/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
